<template>
    <div>
        <p class="text" v-for="text in texts" :key="text">
            {{ $t(text) }}
        </p>
        <div class="screenshot">
            <img class="step-image" src="./assets/step6.png" :alt="$t('faq.firestick.step6.hoverOver')">
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            texts: ['faq.firestick.step6.hoverOver', 'faq.firestick.step6.afterClicking', 'faq.firestick.step6.clickHome']
        }
    }
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
</style>
